<template>
  <div id="app" :class="{ 'app-webview': $store.getters.isDpcMobileWebView }">
    <template v-if="isSiteReady">
      <!-- <OverScreen></OverScreen> -->
      <DpcHeader v-show="!$store.getters.isDpcMobileWebView"></DpcHeader>

      <keep-alive include="Home,CampanhaMain">
        <router-view id="router-view"></router-view>
      </keep-alive>

      <router-view
        id="router-view-secundaria"
        name="rvSecundaria"
      ></router-view>

      <notifications group="dpc" position="bottom right" width="280" />

      <template v-if="!$store.getters.isDpcMobileWebView">
        <DpcFooter v-if="companyIsDpc"></DpcFooter>
        <DrlFooter v-else></DrlFooter>
      </template>

      <ModalCasadinha
        v-if="showModalCasadinha && isAuthenticated"
        :show-modal="showModalCasadinha"
        :integracao_id="Number(casadinhaProduto.integracao_id)"
        :produto_id="casadinhaProduto.cod_produto"
        :src="casadinhaProduto.src"
        :descricao="casadinhaProduto.descricao"
        @close="closeModalCasadinha"
      ></ModalCasadinha>
      <ModalChangePassword
        v-if="showModalChangePassword && isAuthenticated"
        :show-modal="showModalChangePassword"
        :forgot-password="forgotPassword"
        @close="closeModalChangePassword"
      ></ModalChangePassword>
      <!-- <ToolbarPedido v-if="loggedIn"></ToolbarPedido> -->

      <blackmodal v-if="showLoadingModal" class="black-modal">
        <img v-if="loadingAnimUrl" width="110" :src="loadingAnimUrl" alt="" />
        <i v-else class="mdi mdi-loading fa-spin fa-4x text-color-primary"></i>
      </blackmodal>
    </template>
  </div>
</template>

<script>
import cookie from "vue-cookie";

import _capitalize from "lodash/capitalize";

import { mapGetters, mapState } from "vuex";

import { format } from "date-fns";
import store from "@/vuex";
import DpcHeader from "@/components/root/header/top/HeaderTop";
import DpcFooter from "@/components/root/footer/Footer";

// temporário.
import DrlFooter from "@/components/root/footer/FooterDRL";

import ModalCasadinha from "@/components/root/modal/ModalCasadinha";
import ModalChangePassword from "@/components/root/modal/ModalChangePassword";
import ToolbarPedido from "@/components/toolbar-pedido/ToolbarPedido";
import bus from "@/utils/events/bus";

import OverScreen from "@/components/root/overscreen/OverScreen";

import blackmodal from "@/components/root/black-modal/blackModal";

export default {
  name: "App",
  metaInfo() {
    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: "Início",
      // all titles will be injected into this template
      titleTemplate: `%s | ${this.siteConfig?.app_name || ""}`,
      meta: [
        {
          vmid: "application-name",
          name: "application-name",
          content: `${this.siteConfig.app_name}`,
        },
        {
          vmid: "description",
          name: "description",
          content: `${this.siteConfig.app_name}`,
        },
        {
          vmid: "keywords",
          name: "keywords",
          content: this.siteConfig.app_meta_keywords,
        },
        { vmid: "og:url", name: "og:url", content: process.env.VUE_APP_URL },
        {
          vmid: "og:title",
          name: "og:title",
          content: this.siteConfig.app_name,
        },
        {
          vmid: "og:site_name",
          name: "og:site_name",
          content: this.siteConfig.app_name,
        },
        {
          vmid: "og:description",
          name: "og:description",
          content: `${this.siteConfig.app_name}`,
        },
        {
          vmid: "google-site-verification",
          name: "google-site-verification",
          content: `${this.siteConfig.app_google_site_verification}`,
        },
        {
          vmid: "theme-color",
          name: "theme-color",
          content: `${this.siteConfig.app_color_brand_primary}`,
        },
        {
          vmid: "apple-itunes-app",
          name: "apple-itunes-app",
          content: `app-id=${this.siteConfig.app_apple_app_store_id}`,
        },
      ],
    };
  },
  components: {
    DpcHeader,
    DpcFooter,
    DrlFooter,
    OverScreen,
    ToolbarPedido,
    ModalCasadinha,
    ModalChangePassword,
    blackmodal,
  },
  data() {
    return {
      showModalCasadinha: false,
    };
  },
  computed: {
    isSiteReady() {
      return Object.keys(this.siteConfig)?.length > 0;
    },
    loadingAnimUrl() {
      return this.siteConfig.app_loading_anim_url &&
        this.siteConfig.app_loading_anim_url.includes("@")
        ? require(this.siteConfig.app_loading_anim_url)
        : this.siteConfig.app_loading_anim_url;
    },
    companyIsDpc() {
      return this.siteConfig.app_name_short === "DPC";
    },
    globalAppLoading() {
      return this.$store.getters.globalAppLoading;
    },
    isAuthenticated() {
      return this.$store.getters.getIsAuthenticated;
    },
    showModalChangePassword() {
      return this.$store.getters.getUserShouldResetPassword;
    },
    ...mapState(["showLoadingModal"]),
    ...mapGetters({
      siteConfig: "siteConfig",
    }),
  },
  async created() {
    this.validateAndApplyBlackFridayRules();

    this.buscaCategorias();
    this.buscaFornecedores();
    this.buscaInformacaoEmpresa();

    // if (!Account.isAuthenticated()) {
    //   this.loggedIn = false;
    //   localStorage.removeItem("loggedUser");
    // } else {
    //   this.loggedIn = true;
    //   // this.buscaDadosUsuario();
    // }
    // document.cookie.addEventListener("change", (event) => {
    //   console.log(event);
    //   Account.logoff();
    //   this.$router.push("/");
    // });

    const token = cookie.get("token");

    window.onstorage = () => {
      const currToken = cookie.get("token");
      if (token != currToken) {
        window.location.reload();
      }
    };
  },
  mounted() {
    window.jivo_onLoadCallback = () => {
      jivo_api.setWidgetColor(this.siteConfig?.app_color_brand_primary);
      bus.$emit("jivoChatStatus", jivo_api.chatMode());
    };

    // const date = moment().format("DD/MM/YYYY");

    // if (date >= "13/11/2021" && date <= "27/11/2021") {
    //   document.documentElement.style.setProperty("--brand-primary", "#000");
    //   document
    //     .querySelector("meta[name=theme-color]")
    //     .setAttribute("content", "#000");
    // }

    // todo: identificar item que estava carregando antes, e só finalizar quando todos finalizarem.
    // bus.$on("showLoadingModal", (value) => {
    //   if (value) {
    //     this.showLoadingModalRequests++;
    //   } else {
    //     this.showLoadingModalRequests--;
    //   }

    //   if (!value && this.showLoadingModalRequests <= 0) {
    //     this.showLoadingModal = false;

    //     return;
    //   }

    //   this.showLoadingModal = true;
    // });

    // bus.$on("showLoadingModal", (value, id) => {
    //   console.log(value, id);
    //   console.log(this.loadingModalIds);

    //   if (!id) id = 1;
    //   if (value) {
    //     this.showLoadingModalRequests++;
    //     this.loadingModalIds.push(id);
    //   } else {
    //     this.showLoadingModalRequests--;
    //     this.loadingModalIds = this.loadingModalIds.filter(
    //       (modalId) => modalId !== id
    //     );
    //   }

    //   if (
    //     !value &&
    //     this.showLoadingModalRequests <= 0 &&
    //     !this.loadingModalIds.length
    //   ) {
    //     this.showLoadingModal = false;

    //     return;
    //   }

    //   this.showLoadingModal = true;
    // });

    if (!cookie.get("identifier")) {
      fetch("https://api.ipify.org?format=json")
        .then((x) => x.json())
        .then(({ ip }) => {
          cookie.set("identifier", ip.split(".").join(""), 365);
        });
    }
    const that = this;
    document.addEventListener("keydown", (e) => {
      if (e.keyCode == 113) {
        $(`.input-search`).focus();
        $(`.input-search`).select();
      }
      if (e.keyCode == 40) {
        if ($(".input-search").is(":focus")) {
          bus.$emit("table-products-rowselected-change", 0);
        }
        // if (
        //   that.$route.query.visualizacao == "lista-3" &&
        //   that.enableFocus == true
        // ) {
        //   $("#table-produtos table tbody tr:nth-child(1)").addClass(
        //     "row-selected"
        //   );
        //   that.$nextTick(async function() {
        //     $(`#table-produtos table tbody tr.row-selected input`).focus();
        //   });
        //   that.enableFocus = false;
        // }
      }
    });
    // Ativa Evento de Novificação
    bus.$on("show-notification", (params) => {
      this.showNotification(params[0], params[1], params[2]);
    });

    bus.$on("openModalCasadinha", (produto) => {
      this.casadinhaProduto = produto;
      this.openModalCasadinha();
    });

    bus.$on("modalIsOpen", (value) => {
      if (value) {
        // Caso o modal esteja aberto, iremos deixar de escutar o evento keydown.
        document.removeEventListener("keydown", that.keyHandlerEsc);
        $("html").addClass("block-scroll");
      } else {
        // Quando o modal fechar, voltamos a escutar o evento keuydown.
        document.addEventListener("keydown", that.keyHandlerEsc);
        $("html").removeClass("block-scroll");
      }
    });
  },
  async beforeMount() {
    try {
      if (this.siteConfig) {
        this.$store.dispatch("setSiteConfig", this.siteConfig);
      }

      const url = `${process.env.VUE_APP_APIB2B}/v1/site-config`;
      const { data } = await dpcAxios.connection().get(url, {});

      if (data) {
        this.$store.dispatch("setSiteConfig", data);
        localStorage.setItem("siteConfig", JSON.stringify(data));
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      console.error(error);
    }
  },
  beforeDestroy() {
    document.removeEventListener("keydown", (e) => {});
  },
  destroyed() {
    bus.$off("show-notification");
  },
  methods: {
    async validateAndApplyBlackFridayRules() {
      const date = format(new Date(), "dd/MM/yyyy");

      const bfStart = this.siteConfig.app_black_week_start_date;
      const bfEnd = this.siteConfig.app_black_week_end_date;

      if (bfStart && bfEnd && date >= bfStart && date <= bfEnd) {
        const colorBrandPrimary =
          this.siteConfig.app_black_friday_primary_color || "#000";
        document.documentElement.style.setProperty(
          "--brand-primary",
          colorBrandPrimary
        );
        document.documentElement.style.setProperty(
          "--brand-secondary",
          colorBrandPrimary
        );

        document
          .querySelector("meta[name=theme-color]")
          .setAttribute("content", colorBrandPrimary);

        const logoBfUrl =
          date == this.siteConfig.app_black_friday_date
            ? await this.siteConfig.app_black_friday_logo
            : await this.siteConfig.app_black_week_logo;
        if (logoBfUrl) {
          document.getElementsByClassName("img-logo-header")[0].src = logoBfUrl;
          document.getElementsByClassName("img-logo-header-mobile")[0].src =
            logoBfUrl;
        }
      }
    },
    // todo: mover para beforeeach.
    // async buscaDadosUsuario() {
    //   if (!this.loggedIn) return;

    //   const { data } = await dpcAxios
    //     .connection()
    //     .get(`${process.env.VUE_APP_ECOMMERCE}ec/minha-conta/usuario`);

    //   if (!data.usuario.novasenha) {
    //     this.showModalChangePassword = true;
    //   }

    //   localStorage.setItem("loggedUser", JSON.stringify(data.usuario));

    //   // dispatch setUsuarioInfo to vuex
    //   this.$store.dispatch("setUsuarioInfo", data.usuario);

    //   gtag("set", { user_id: data.usuario.usuario_id }); // Set the user ID using signed-in user_id.
    // },
    openModalCasadinha() {
      if (!this.isAuthenticated) return;

      this.showModalCasadinha = true;
    },
    // openModalChangePassword() {
    //   this.showModalChangePassword = true;
    // },
    closeModalCasadinha() {
      this.showModalCasadinha = false;
    },
    closeModalChangePassword() {
      // this.showModalChangePassword = false;
      this.$store.dispatch("setUserShouldResetPassword", false);
    },
    showNotification(tipo, title, mensagem) {
      this.$notify({
        // Nome do Grupo
        group: "dpc",

        // Classe Style Options: warn, error, success
        type: tipo,

        // Title
        title,

        // Content
        text: mensagem,

        duration: 6500,

        classes: "vue-notification",
      });
    },
    buscaFornecedores() {
      dpcAxios
        .connection()
        .post(process.env.VUE_APP_ECOMMERCE + api.env.FORNECEDOR)
        .then((response) => {
          // this.posts = response.data
          const fornecedor = [];
          if (response.data.fornecedores.length) {
            $.each(response.data.fornecedores, (index, value) => {
              fornecedor[index] = {
                descricao: _capitalize(value.fantasia || value.nomerazao),
                codigo: value.id,
              };
            });
          }
          this.$store.dispatch("setParceiros", fornecedor);
        })
        .catch((e) => {
          console.error(e);
        });
    },
    buscaCategorias() {
      dpcAxios
        .connection()
        .post(`${process.env.VUE_APP_ECOMMERCE + api.env.CATEGORIA}/showall`, {
          retfilhos: 1,
        })
        .then((response) => {
          // this.posts = response.data

          if (response.data.error == 0) {
            const { categorias } = response.data;

            for (let i = 0; i < categorias.length; i++) {
              categorias[i].descricao = _capitalize(categorias[i].descricao);
              categorias[i].codigo = categorias[i].integracao_id;
              if (categorias[i].filhos) {
                for (let y = 0; y < categorias[i].filhos.length; y++) {
                  categorias[i].filhos[y].descricao = _capitalize(
                    categorias[i].filhos[y].descricao
                  );

                  categorias[i].filhos[y].codigo =
                    categorias[i].filhos[y].integracao_id;
                }
              }
            }
            this.$store.dispatch("addItemCategoria", categorias);
          }
        })
        .catch((e) => {
          console.error(e);
        });
    },
    buscaInformacaoEmpresa() {
      dpcAxios
        .connection({ withCredentials: false })
        .get(process.env.VUE_APP_ENDERECO + api.env.EMPRESA_INFORMACAO)
        .then((response) => {
          // this.posts = response.data

          if (response.data.length > 0) {
            this.$store.dispatch("setInformacaoGlobal", response.data[0]);
          }
        })
        .catch((e) => {
          console.error(e);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.global-app-loading {
  height: 80vh;
}
.modalLoading {
  background-color: rgba(0, 0, 0, 0.63);
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 8888;
  left: 0;
  top: 0;

  .loading {
    position: absolute;
    top: 50%;
    left: 50%;
    color: white;
  }

  display: block !important;
}
</style>

<style lang="scss">
#app:where(:has(#header)):has(#toolbar-seller) {
  #router-view {
    margin-top: 190px;
  }
}

#app:where(:has(#header)):has(#toolbar-seller):has(.razao-select) {
  #router-view {
    margin-top: 220px;
  }
}

#app:where(:has(#header)):has(.razao-select) {
  #router-view {
    margin-top: 180px;
  }
}

.no-scroll {
  overflow: hidden;
}

.img-responsive {
  max-height: 100%;
}
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.vue-notification-group {
  bottom: 50px !important;
}

@import "~@/assets/css/sass/bootstrap/variables";
@keyframes shakeError {
  0% {
    transform: translateX(0);
  }
  15% {
    transform: translateX(0.375rem);
  }
  30% {
    transform: translateX(-0.375rem);
  }
  45% {
    transform: translateX(0.375rem);
  }
  60% {
    transform: translateX(-0.375rem);
  }
  75% {
    transform: translateX(0.375rem);
  }
  90% {
    transform: translateX(-0.375rem);
  }
  100% {
    transform: translateX(0);
  }
}

.form-shake-error {
  animation-name: shakeError;
  animation-fill-mode: forwards;
  animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// .btn {
//   // height: 45px;
//   padding: 6px 15px;
//   text-align: center;
//   font-family: "Open Sans";
//   font-style: normal;
//   font-weight: bold;
//   font-size: 14px;
//   // letter-spacing: 0.4px;
//   transition: 200ms ease-in;
//   border-width: 2.2px;

//   &.btn-sm {
//     padding: 5px 10px;
//     font-size: 13px;
//     line-height: 1.5;
//     border-radius: 5px;
//   }

//   &:disabled {
//     cursor: not-allowed;
//     opacity: 0.65;
//     background-color: #276f47 !important;
//   }
// }

.btn-outline {
  border-width: 2.5px !important;
}

.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 2.5s linear infinite;
  animation: progress-bar-stripes 2.5s linear infinite;
}

.btn-primary {
  background: var(--brand-primary, $brand-primary) !important;
  color: #fff;
  position: relative;

  &:hover {
    background: darken($brand-primary, 10%) !important;
    // background-color: hsl(from var(--brand-primary) h s calc(l-10%)) !important;
    // background-color: rgba(
    //   var(--brand-primary, $brand-primary),
    //   0.5
    // ) !important;
    // background: var(--brand-secondary, $brand-secondary) !important;
  }
}
.text-warning {
  color: #d07604 !important;
}
*:focus {
  outline: 0;
}

* {
  touch-action: manipulation;
}

@media (min-width: 1295px) {
  .container {
    width: 98%;
    max-width: 1360px;
  }
}

.text-color-primary {
  color: var(--brand-primary, $brand-primary);
}

.text-color-secondary {
  color: var(--brand-secondary, $brand-secondary);
}

.text-color-accent {
  color: var(--brand-accent, $brand-accent);
}

/*@media (min-width: 1035px) {
  .container {
    width: 1320px;
  }
}

@media (min-width: 991px) {
  .container {
    width: 1230px;
  }
}*/

@media (min-width: 768px) {
  .container {
    width: 100% !important; // fixing that crap
  }
}

// @media (max-width: 525px) {
//   html {
//     background-color: var(--brand-primary, $brand-primary);
//   }
// }
</style>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700,800");
@import url("https://fonts.googleapis.com/css2?family=Sora:wght@100;200;300;400;500;600;700;800&display=swap");

#router-view {
  background: #f3f3f3;
  // margin-top: 182px;
  margin-top: 151px;
  // font-family: "Montserrat", sans-serif !important;
  min-height: 500px;

  &.active {
    display: block;
  }
}

@media (max-width: 991px) {
  #router-view {
    margin-top: 150px;
    min-height: 0px;
  }
}

@media (max-width: 525px) {
  #router-view {
    // margin-top: 60px;
    // margin-top: 143px;
    // margin-top: 182px;
    margin-top: 140px;
  }

  #app {
    background: white;
  }
}

.app-webview {
  margin-top: -151px !important;

  jdiv,
  .jivo-iframe-container {
    display: none;
  }
}
</style>
